<template>
  <div class="page">
    <div class="edit_box">
      <div class="item">
        <div :class="['left_box', { disable_box: checkStatusFlag == '0' }]">
          社群名称：
        </div>
        <div class="right_box">
          <input
            :disabled="checkStatusFlag == '0'"
            type="text"
            v-model="form.associationName"
          />
        </div>
      </div>
      <div class="item">
        <div :class="['left_box', { disable_box: checkStatusFlag == '0' }]">
          关键词：
        </div>
        <div class="right_box">
          <input
            :disabled="checkStatusFlag == '0'"
            type="text"
            v-model="form.content"
          />
        </div>
      </div>
      <div class="item">
        <div :class="['left_box', 'disable_box']">社群组织人：</div>
        <div class="right_box">
          <input
            class="disable_box"
            disabled
            style="color: rgba(0, 0, 0, 0.85)"
            type="text"
            v-model="this.userInfo.userName"
          />
        </div>
      </div>
      <div class="item">
        <div :class="['left_box', 'disable_box']">社群成立时间：</div>
        <div class="right_box">
          <input
            class="disable_box"
            disabled
            style="color: rgba(0, 0, 0, 0.85)"
            type="text"
            v-model="form.createTime"
          />
        </div>
      </div>
      <div class="bottom_box">
        <v-input
          :disabled="checkStatusFlag == '0'"
          styleStr="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
          v-model="form.description"
          type="textarea"
          placeholder="社群介绍"
          :maxlength="300"
          :showWordLimit="true"
        />
      </div>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic"
        :activityPicture.sync="form.proposalPic"
        :maxCount="1"
        ref="load"
      ></v-upload>
      <p>请上传您的社群照片，建议单个图片不超过5M。</p>
    </div>
    <div class="refuse_btn" @click="dissolution(1)">解散</div>
    <div
      class="agree_btn"
      v-if="checkStatusFlag == '1'"
      @click="submitApply(2)"
    >
      提交申请
    </div>
    <div class="agree_btn" v-else>审核中</div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="dialog_text" v-html="dialog_text"></p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getAssDetailForMiniUrl,
  userInfoUrl,
  dissolveAssociationUrl,
  commitApplyUrl,
  checkStatusUrl,
} from "./api.js";
export default {
  name: "editCommunityInformation",
  data() {
    return {
      userInfo: [],
      form: {
        proposalPic: "",
        associationName: "",
        content: "",
        description: "",
        directorName: "",
        createTime: "",
      },
      isDialog: false,
      dialog_text: "",
      id: null,
      detail: null,
      dialogType: null,
      checkStatusFlag: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getUserInfo();
    console.log(this.id);
  },
  mounted() {
    this.getDetail();
    this.checkStatus();
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    checkStatus() {
      let params = {
        associationId: this.id,
      };
      this.$axios
        .get(`${checkStatusUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.checkStatusFlag = res.data;
          }
        });
    },
    getUserInfo() {
      let params = {
        userId: this.userId,
      };

      this.$axios
        .get(`${userInfoUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    getDetail() {
      //获取社群详情
      let params = {
        userId: this.userId,
        associationId: this.id,
      };
      this.$axios
        .get(`${getAssDetailForMiniUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.form.proposalPic = res.data[0].picture;
            this.form.associationName = res.data[0].associationName;
            this.form.description = res.data[0].description;
            this.form.content = res.data[0].content;
            this.form.directorName = res.data[0].directorName;
            this.form.createTime = res.data[0].createTime;
          }
        });
    },
    dissolution(type) {
      this.dialogType = type;
      this.dialog_text = `您是否确定解散社群<br>解散后不可撤回哦~`;
      this.isDialog = true;
    },
    submitApply(type) {
      this.dialogType = type;
      this.dialog_text = "是否确认提交申请?";
      this.isDialog = true;
    },
    confirm() {
      if (this.dialogType == 1) {
        let params = {
          userId: this.userId,
          associationId: this.id,
        };
        this.$axios
          .post(`${dissolveAssociationUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$toast("解散该社群操作成功！");
              this.$router.replace({
                name: "myCommunity",
              });
            } else {
              this.$toast(res.msg);
            }
          });
      } else if (this.dialogType == 2) {
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        if (!this.form.proposalPic) {
          this.$toast("请上传图片");
          return;
        }
        if (this.form.associationName == "") {
          this.$toast("社群名称不能为空！");
          return;
        }
        if (this.form.description == "") {
          this.$toast("社群介绍不能为空！");
          return;
        }
        if (this.form.content == "") {
          this.$toast("关键词不能为空！");
          return;
        }
        let params = {
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          userName: this.userInfo.userName,
          associationId: this.id,
          associationName: this.form.associationName,
          description: this.form.description,
          content: this.form.content,
          picture: this.form.proposalPic,
        };
        this.$axios.post(`${commitApplyUrl}`, params).then((res) => {
          if (res.code === 200) {
            this.checkStatus();
          }
        });
      }
      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;

  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .agree_btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc054 0%, #fe9716 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    margin-top: 34px;
  }

  .refuse_btn {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    border: 2px solid;
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #fe9717;
    margin: 0 auto;
    margin-top: 124px;
  }

  p {
    margin-top: 18px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
  }

  .edit_box {
    width: 100%;
    padding: 0 52px 0 44px;
    box-sizing: border-box;

    .bottom_box {
      margin-top: 32px;
      width: 100%;
      margin-bottom: 38px;
      box-sizing: border-box;
    }

    .item {
      width: 100%;
      height: 106px;
      box-sizing: border-box;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      display: flex;
      line-height: 106px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);

      .left_box {
        font-size: 32px;
        color: #333333;
      }

      .disable_box {
        cursor: default;
        background-color: #fafafa;
      }

      .right_box {
        flex: 1;
        box-sizing: border-box;

        input {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          text-align: right;
        }
      }
    }
  }
}
</style>
